<script setup lang="ts">

	
	const route = useRoute();
	
	const {useBurgerMenuIsOpen, useSubmitMenuIsOpen} = useModal();
	const burgerMenuIsOpen = useBurgerMenuIsOpen();
	
	const submitMenuIsOpen = useSubmitMenuIsOpen();

	const links = [
		{
			href: '/',
			text: 'О нас',
			icon: 'nav/home'
		},
		{
			href: '/flats',
			text: 'Квартиры',
			icon: 'nav/flat'
		},
		{
			href: '/gallery',
			text: 'Галлерея',
			icon: 'nav/gallery'
		},
		{
			href: '/documents',
			text: 'Документы',
			icon: 'nav/docs'
		},
		{
			href: '/contacts',
			text: 'Контакты',
			icon: 'nav/contacts'
		},
	]
	
	const scrollPosition = ref<number>(0);
	const staticMenuIsVisible = ref<boolean>(false);

	
	watchEffect(() => {
		staticMenuIsVisible.value = scrollPosition.value > 150 || burgerMenuIsOpen.value || submitMenuIsOpen.value;
	})
	
	
	
	onMounted(() => {
		window.addEventListener('scroll', event => {
			scrollPosition.value = window.scrollY;
		})
	});


</script>

<template>
	<VSlideYTransition>
		<VAppBar
			class="nav"
			v-if="staticMenuIsVisible"
		>
			<MenuTemplate/>
		</VAppBar>
	</VSlideYTransition>
	
	

</template>

<style scoped lang="scss">

	
	
	
	.nav {
		
		&__right {
			display: flex;
			align-items: center;
			gap: 16px;
		}
		
		&__phone {
			font-weight: bold;
		}
		
		&__body {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
		&__content {
			display: flex;
			align-items: center;
			height: 100%;
		}
		&__links {
			display: flex;
			gap: 32px;
		}
		
		
	}
	
	.link {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
		
		&.active &__text {
			color: $accent;
		}
		
		&__text {
			display: block;
			font-family: 'CasperBold', sans-serif;
		}
	}

</style>