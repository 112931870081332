<script setup lang="ts">

</script>

<template>
	<footer class="footer">
		<div class="wrapper footer__body">
			<address class="footer__location location">
				<h4 class="location__title">
					Офис продаж
				</h4>
				<div class="location__info">
					<div class="location__label">
						Адрес
					</div>
					<div class="location__text">
						г. Сочи, Цурюпы 13Д
					</div>
				</div>
				<div class="location__info">
					<div class="location__label">
						Телефон
					</div>
					
					<a class="location__text" href="tel:+7 (938) 450-99-88">
						+7 (938) 450-99-88
					</a>
					
				</div>
				<div class="location__info">
					<div class="location__label">
						Время работы
					</div>
					<div class="location__text">
						Без выходных: с 10:00 до 18:00
					</div>
				</div>
			</address>
			<div class="footer__copyright copyright">
				<div class="copyright__title">
					© 2024, ООО «Гранд Инвест Холдинг»
				</div>
				<div class="copyright__text">
					Вся размещённая информация носит исключительно рекламный характер и ни при каких условиях не является публичной офертой, определяемой положениями ГК РФ. Указанные качественные характеристики, варианты визуализации объектов не обладают признаками абсолютной идентичности проектной и рабочей документации и размещены исключительно в рекламных целях. Предложение не является публичной офертой. Подробная информация отражена в проектной документации.
				</div>
			</div>
			<div class="footer__sitemap sitemap">
				<NuxtLink class="sitemap__item" to="/">O нас</NuxtLink>
				<NuxtLink class="sitemap__item" to="/flats">Квартиры</NuxtLink>
				<NuxtLink class="sitemap__item" to="/gallery">Галлерея</NuxtLink>
				<NuxtLink class="sitemap__item" to="/documents">Документы</NuxtLink>
				<NuxtLink class="sitemap__item" to="/contacts">Контакты</NuxtLink>
			</div>
		</div>
	
	</footer>
</template>

<style scoped lang="scss">

	.sitemap {
		@media screen and (max-width: 860px) {
			justify-self: left;
		}
		
		justify-self: right;
		display: flex;
		flex-direction: column;
		gap: 8px;
		
		&__item {
			font-size: 20px;
			font-family: 'CasperBold', sans-serif;
			text-decoration: none;
			color: $text-main;
		}
	}

	.copyright {
		&__title {
			margin-bottom: 24px;
		}
		
		&__text {
			font-size: 12px;
			color: $text-gray;
		}
	}
	
	.location {
		font-style: normal;
		&__title {
			font-size: 20px;
		}
		
		&__label {
			font-size: 12px;
			margin-top: 16px;
			font-family: 'CasperBold', sans-serif;
			color: $text-gray;
		}
		
		&__text {
			color: $text-main;
		}
	}
	.footer {
		background: #FFFFFF;
		padding: 32px 0;
		margin-top: 64px;
		@media screen and (max-width: 860px) {
			&__body {
				grid-template-columns: 1fr !important;
				gap: 24px;
			}
		}
		
		&__body {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

</style>